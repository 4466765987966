.songs {
  margin-bottom: 2rem;
}

@media screen and (max-width: 992px) {
  .songs__table {
    min-height: 500px;
  }
}

.songs tbody .icon {
  font-size: 25px;
}

.search-icon .icon {
  color: var(--text-disabled);
}