.song-parts {
  margin-bottom: 1rem;
}

.song-parts__part {
  padding: 0.3em;
  cursor: default;
  display: flex;
  justify-content: space-between;
}

.song-parts .song-parts__part:first-child {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.song-parts .song-parts__part:last-child {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.song-parts__info .icon {
  margin-right: 5px;
  position: relative;
  top: -1px;
}

.song-parts__controls .icon {
  margin: 0 0.2rem;
}

/* DARK THEME */

body.dark {
  .song-parts {
    .song-parts__part {
      border-bottom: 1px solid rgba(0, 0, 0, 0.08);

      &:last-child {
        border-bottom: none;
      }
    }

    .song-parts__info {
      background-color: var(--gray1-opacity);
      border-radius: 6px;
      padding: 0 11px 0 8px;
    }

    .song-parts__controls {
      background-color: var(--gray1-opacity);
      border-radius: 6px;

      .icon svg {
        stroke: var(--gray6);
      }
    }

    .icon svg {
      stroke: var(--gray10);
    }
  }
}

/* MEDIA QUERIES */

@media only screen and (max-width: 768px) {
  .song-parts__info .icon {
    top: 3px;
  }

  .song-parts__name {
    position: relative;
    top: 4px;
    left: 0.2rem;
  }

  .song-parts__controls .icon {
    margin: 0 0.5rem;
    position: relative;
    top: 3px;

    svg {
      width: 24px;
    }
  }

  .song-parts__part {
    min-height: 44px;
  }
}
