.AddToService {
  .icon {
    position: relative;
    left: 3px;
  }

  .icon.solid svg {
    stroke: transparent;
  }
}
