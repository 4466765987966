/* view header */

.view-header {
  font-size: 0.9rem;
  white-space: pre-wrap;

  p {
    margin: 0;
  }

  .song-details-container {
    display: flex;
  }

  .song-info {
    display: flex;
  }

  .song-title {
    font-size: 1.3rem;
    font-weight: 600;
  }

  .song-artist {
    display: block;
  }

  .song-key,
  .song-bpm {
    margin-right: 0.5rem;
    position: relative;
    top: 0.1rem;
  }

  .song-key span,
  .song-bpm span {
    font-weight: 700;
  }

  .youtube {
    position: relative;
    top: -1px;

    .label {
      position: relative;
      top: 2px;
    }

    .icon svg {
      stroke: var(--red-color);
    }

    &:link,
    &:visited {
      color: var(--red-color);
      font-weight: 700;
      text-decoration: none;
    }
  }

  .plugins {
    cursor: pointer;
    margin-left: auto;
    position: relative;
    top: 0.2rem;

    .icon svg {
      position: relative;
      top: -3px;
      width: 24px;
    }
  }

  .song-title a {
    color: var(--gray10);
    text-decoration: none;
  }
}

.view-song .header-line {
  display: block;
  margin: 0.3rem 0 0.7rem 0;
  width: 100%;
  height: 5px;
  background-color: var(--primary-color);
}

/* Auto Scroll Plugin sticky hack */

.view__service-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: right;
  z-index: 2;
  background-color: #fff;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: -1px;
  padding-top: 1px;
}

.view__service-bar .auto-scroll-plugin.mirror {
  display: none;
  /* transition: opacity 0.1s; */
}

.view__service-bar.pinned .auto-scroll-plugin.mirror {
  display: block;
}

.view__service-bar.pinned .view__service-bar__title.scrolling {
  display: none;
}
/* when displaying only view__service-bar */
.header-line + .view__service-bar {
  margin-top: -0.5rem;
}
/* when displaying plugins and view__service-bar */
.header-line + .song-plugins + .view__service-bar {
  margin-top: -0.2rem;
}

.view__service-bar + #view-body {
  margin-top: 0;
}

.view__service-bar__title {
  float: left;
  display: flex;
  align-items: center;
  position: relative;
  top: 0.1rem;
}

.view__service-bar__action-icons .icon {
  font-size: 3rem;
  padding-top: 5px;
}

.view__service-bar__icon {
  font-size: 20px;
  margin-right: 0.3rem;
  position: relative;
  top: -1px;
}

.view__service-bar__action-icons {
  position: relative;
  right: -0.3rem;
  flex-shrink: 0;
}

/* View Body */

#view-body {
  white-space: pre-wrap;
  margin-top: 1rem;
  font-weight: 400;
  font-style: normal;
}

#view-body .song-count {
  display: block;
  font-size: 1rem;
  margin-bottom: 1.3rem;
  margin-left: -0.3rem;
}

.music-tag {
  padding: 0.1rem 0.2rem;
  border-radius: 0.25rem;
  display: inline-block;
  margin-bottom: 0.3rem;
  font-weight: 500;
}

.music-tag__note {
  font-style: italic;
  color: var(--gray6);
  max-width: 380px;
  display: inline-block;
}

.chord {
  color: var(--primary-color);
  font-weight: 600;
}

.part-comment {
  background-color: var(--gray2);
  border-radius: 0.25rem;
  color: var(--gray6);
  display: inline-block;
  margin: 0.1rem 0;
  padding: 0.1rem 0.2rem;
  position: relative;
  top: 0.1rem;
}

/* Next Song Button */

.view-body__next-button {
  display: inline-block;
  margin-top: 1rem;
  cursor: pointer;
  background-color: var(--primary-color);
  color: var(--gray0);
  padding: 0.5rem 1rem;
  font-weight: 500;
  text-align: center;
  border-radius: 30px;
}

body.dark .view-body__next-button {
  color: var(--gray10);
}

@media only screen and (max-width: 991px) {
  body.light .view-body__next-button,
  body.dark .view-body__next-button {
    display: block;
  }
}

body.light .intro,
body.light .music,
body.light .instrumental,
body.light .outro {
  background-color: #ffc6c6;
}

body.light .verse {
  background-color: #def7fd;
}

body.light .pre-chorus {
  background-color: #c3ffc8;
}

body.light .chorus,
body.light .refrain {
  background-color: #ebdefd;
}

body.light .bridge {
  background-color: #fff3c3;
}

body.light .note {
  background-color: #ffc3de;
}

body.dark .music-tag__note {
  color: var(--gray5);
}

body.dark .verse,
body.dark .pre-chorus,
body.dark .chorus,
body.dark .refrain,
body.dark .bridge,
body.dark .intro,
body.dark .music,
body.dark .instrumental,
body.dark .outro,
body.dark .note {
  color: var(--gray10);
}

body.dark .intro,
body.dark .music,
body.dark .instrumental,
body.dark .outro {
  background-color: #ff6161;
}

body.dark .verse {
  background-color: var(--primary-color);
}

body.dark .pre-chorus {
  background-color: #57af6d;
}

body.dark .chorus,
body.dark .refrain {
  background-color: var(--secondary-color-lighter);
}

body.dark .bridge {
  background-color: #f5d862;
}

body.dark .note {
  background-color: #ff98c6;
}

body.dark .part-comment {
  background-color: var(--gray8);
  color: var(--gray4);
}

body.dark .song-parts ion-icon {
  color: var(--gray10);
}

/* plugins */

.song-plugins {
  display: flex;
  width: 100%;
  height: 60px;
  color: var(--primary-color);
  margin-bottom: 0.2rem;
  user-select: none; /* standard syntax */
  -webkit-user-select: none; /* webkit (safari, chrome) browsers */
  -moz-user-select: none; /* mozilla browsers */
  -khtml-user-select: none; /* webkit (konqueror) browsers */
  -ms-user-select: none; /* IE10+ */
}

.song-plugin {
  display: inline-block;
  margin-right: 1.3rem;
}

.plugin__title {
  text-transform: uppercase;
  display: block;
  font-size: 0.7rem;
  font-weight: bold;
  margin: 0 0 0.4rem 0;
}

.song-plugin .display-value {
  display: inline-block;
  text-align: center;
  width: 45px;
  cursor: default;
  font-weight: 600;
  font-size: 0.9rem;
  position: relative;
  top: 0.1rem;
}

.view-song .song-plugin .btn-increase-value,
.view-song .song-plugin .btn-decrease-value {
  cursor: pointer;
}

/* auto scroll plugin */

.auto-scroll-plugin .selected {
  color: #fff;
  border-color: var(--primary-color);
  background-color: var(--primary-color);
}

.auto-scroll-plugin.mirror {
  margin-right: 0;
}

.auto-scroll-plugin__btn {
  cursor: pointer;
  display: inline-block;
  width: 2rem;
  height: 2rem;
  margin-right: 0.4rem;
  text-align: center;
  border-radius: 0.3rem;
  color: var(--primary-color);
  border: 2px solid var(--primary-color);
}

.auto-scroll-plugin__btn span {
  position: relative;
  top: 0.2rem;
}

.auto-scroll-plugin__btn:last-child {
  margin-right: 0;
}

body.dark .auto-scroll-plugin__btn {
  color: var(--gray3);
  border-color: var(--gray3);
}

body.dark .auto-scroll-plugin .selected {
  color: var(--gray10);
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

body.dark .view-song .song-plugins {
  color: var(--gray3);
}

body.dark .view__service-bar__icon svg {
  stroke: var(--gray3);
}

body.dark .view-header .youtube .icon svg {
  stroke: var(--red-color-lighter);
}

body.dark .view-header a.youtube:link,
body.dark .view-header a.youtube:visited {
  color: var(--red-color-lighter);
}

body.dark .view__service-bar {
  background: var(--gray10);
}
