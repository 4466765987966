/* songs menu */

.songs-menu-container {
  background-color: #fcfcfc;
  padding: 0.4rem 0;
  margin-bottom: 20px;
  margin-top: -20px;
  z-index: 1;

  ul.songs-menu {
    padding: 0;
    margin: 0;
    position: relative;
    top: 2px;

    .icon {
      svg {
        width: 22px;
      }
    }

    li {
      margin: 0.1rem 1.7rem 0.5rem 0;
      display: inline-block;
    }

    .btn:hover {
      text-decoration: none;
    }

    span.label {
      color: var(--primary-color);
    }

    .service-count {
      color: #fff;
      background-color: var(--primary-color);
      padding: 0.1rem 0.3rem;
      border-radius: 5px;
    }
  }
}

/* DARK THEME */

body.dark .songs-menu-container {
  background-color: var(--gray9);

  ul.songs-menu {
    .icon {
      color: var(--gray3);
    }
    
    span {
      color: var(--gray3);
    }
    
    .service-count {
      background-color: var(--gray7);
    }
  }
}


