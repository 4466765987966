.no-content {
  .icon svg {
    fill: transparent;
    stroke: var(--primary-color);
  }

  .label {
    position: relative;
    top: 2px;
  }

  .no-content-loader {
    margin-bottom: 1.15rem;
  }
}

/* DARK THEME */

body.dark .no-content .icon svg {
  fill: transparent;
  stroke: var(--primary-color);
}
