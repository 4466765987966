.SongList {
  ul {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .song-list-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
    padding: 8px;
    border-radius: 8px;
    user-select: none;

    body.dark & {
      /* inner glow 👇 */
      box-shadow: inset 0 0 0.5px 1px hsla(0, 0%, 100%, 0.075), /* shadow ring 👇 */ 0 0 0 1px hsla(0, 0%, 0%, 0.05),
        /* multiple soft shadows 👇 */ 0 0.3px 0.4px hsla(0, 0%, 0%, 0.02), 0 0.9px 1.5px hsla(0, 0%, 0%, 0.045),
        0 3.5px 6px hsla(0, 0%, 0%, 0.09);
    }

    .song-name,
    .song-artist {
      margin: 0;
    }

    body.dark & {
      .song-name a {
        color: var(--primary-color-dark-mode-text);
      }
    }

    .song-artist {
      font-size: 0.8em;
    }

    .song-actions {
      display: flex;
      gap: 10px;
      align-items: center;
      justify-content: center;
      position: relative;
      top: -1px;

      .icon svg {
        width: 24px;
      }
    }
  }
}
