.my-team {
  text-align: center;
  margin: 2rem auto;
  max-width: 700px;
}

.icon {
  &.my-team__flag {
    svg {
      width: 48px;
    }
  }
}

.my-team h2 {
  background-color: var(--primary-color);
  background-image: linear-gradient(45deg, var(--primary-color), var(--secondary-color));
  background-size: 100%;
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}

.team-members {
  margin: 1rem 0 1.5rem 0;
  position: relative;
}

.team-members__count {
  position: relative;
  top: -0.3rem;
}

.team-members__member {
  display: inline-block;
  margin: 0.2rem;
  padding: 0.2rem 0.6rem;
  border-radius: 15px;
  color: #fff;
  background-color: var(--primary-color);
}

body.dark .team-members__member {
  color: var(--gray10);
}

.my-team .team-id .icon {
  position: relative;
  top: -1px;
  margin-left: 5px;
}

.my-team .hidden {
  opacity: 0;
  display: block;
  width: 100px;
  overflow: hidden;
}
