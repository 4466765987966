.Options {
  float: right;

  .icon svg {
    width: 24px;
  }
}

.Options a {
  padding: 0;
  outline: none;
}

.Options button:active {
  background-color: var(--shadow-color);
}

.options__icon {
  position: relative;
  top: -1px;
}

.options__title {
  color: var(--primary-color);
  margin-left: 0.4rem;
}

.options__delete .options__title {
  color: var(--red-color);
}

.options__delete .options__icon {
  svg {
    stroke: var(--red-color-lighter);
  }
}

/* DARK THEME */
body.dark .options__delete .options__icon svg {
  stroke: var(--red-color-lighter);
}
body.dark .options__delete .options__title {
  color: var(--red-color-lighter);
}
