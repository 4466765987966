#search-box-scroll-id {
  scroll-margin-top: 55px;
}

.search-box-container {
  position: relative;

  .search-box {
    padding: 1.2em 1em;
    border-radius: 40px;
  }
  
  span {
    position: absolute;
    right: 8px;
    top: 3px;
    z-index: 0;

    .icon {
      svg {
        stroke: var(--gray3);
        width: 24px;
      }
    }
  }
  
  .search-clear-btn .icon {
    svg {
      fill: transparent;
      width: 24px;
      position: relative;
      left: 0.1rem;
    }
  }
}
