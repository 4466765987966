.top-menu {
  background-color: #fcfcfc;
  padding: 0.7rem 0;
  margin-bottom: 20px;
  margin-top: -20px;
  z-index: 1;
}

body.dark .top-menu {
  background-color: var(--gray9);
}

.top-menu__items {
  display: flex;
  align-items: center;
  margin: 0;

  .top-menu__item {
    display: inline-block;
    color: var(--primary-color);

    .top-menu__title {
      margin-left: 0.3rem;
    }
  }
}

body.dark .top-menu__item {
  color: inherit;
}

.top-menu__item .icon {
  svg {
    width: 22px;
    color: var(--primary-color);
    position: relative;
    top: -2px;
  }
}

body.dark .top-menu__item .icon {
  color: var(--gray3);
}
